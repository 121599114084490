<template>
  <div class="fullScreen" :class="{ show: true }" v-loading="loading">
    <Crumbs> </Crumbs>
    <div class="fullScreenMain">
      <div class="fullScreenMainCon">
        <el-card
          class="box-card"
          style="margin-top: 20px"
          v-for="(item, index) in formData"
          :key="index">
          <div slot="header" class="clearfix" v-if="formData[index][0]">
            <span style="color: #409eff; font-size: 18px; font-weight: 900">{{
              formData[index][0].disbursementType | dict(dictData.disbursementType)
            }}</span>
          </div>
          <template v-for="contentItem in item">
            <div class="memberClass" :key="contentItem.id">
              <span>
                <span class="ClassName">{{
                  contentItem.code | dict(dictData.disbursementRole)
                }}</span
                >:
                <el-input
                  @blur="blurFn(contentItem.id)"
                  v-model.number="contentItem.ratio"
                  :class="[
                    contentItem.sign == 1 ? 'color bd_input percentage' : ' bd_input percentage',
                  ]"></el-input>
              </span>
            </div>
          </template>
        </el-card>
      </div>
      <div class="fullScreenOperation">
        <el-button type="primary" @click="submit" v-loading="loading">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  data() {
    return {
      loading: false,
      dictData: {
        disbursementType: [], //支出类型
        disbursementRole: [], //角色类型
      },
      formData: [], //页面数据
      oldData: [],
    }
  },
  computed: {},
  created() {
    this.getType()
  },
  methods: {
    // 提交
    submit() {
      this.loading = true
      const arr = []
      this.formData.forEach(list => {
        list.forEach(item => {
          this.oldData.forEach(v => {
            if (v.id == item.id && v.ratio != item.ratio) {
              let obj = {
                id: item.id,
                ratio: item.ratio,
              }
              arr.push(obj)
            }
          })
        })
      })
      this.$api.disburementRatio
        .edit(arr)
        .then(res => {
          this.$message.success('修改成功')
          this.getRatio()
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    blurFn(id) {
      var num = this.oldData.find(v => v.id === id)?.ratio
      this.formData.forEach(list => {
        list.forEach(item => {
          if (item.id == id) {
            if (item.ratio != num) {
              item.sign = 1
            } else {
              item.sign = 0
            }
          }
        })
      })
    },
    // 获取数据库中的支出比例数据 edit
    async getRatio() {
      const res = await this.$api.disburementRatio.list()
      const arr = []
      if (res.data) {
        this.oldData = res.data.deepClone()
        res.data.forEach(function (item) {
          item.sign = 0
          item.ratio = Number(item.ratio)
          switch (item.disbursementType) {
            case 'THROUGH_RATIFICATION_IMPLEMENT':
              if (!arr[0]) {
                arr[0] = []
              }
              arr[0].push(item)
              break
            case 'UNDEFINED_RATIFICATION_IMPLEMENT':
              if (!arr[1]) {
                arr[1] = []
              }
              arr[1].push(item)
              break
            case 'COORDINATE_IMPLEMENT':
              if (!arr[2]) {
                arr[2] = []
              }
              arr[2].push(item)
              break
            default:
              break
          }
        })
        this.formData = arr
      }
    },
    getType() {
      this.$api.dict
        .listSysDictData('BUSINESS_DISBURSEMENT_ROLE', true)
        .then(res => {
          this.dictData.disbursementRole = res?.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_DISBURSEMENT_TYPE', true)
        .then(res => {
          this.dictData.disbursementType = res?.data
          this.getRatio()
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.memberClass {
  display: inline-block;
  width: 250px;
}
.ClassName {
  color: #000;
  display: inline-block;
  width: 85px;
  text-align: center;
}

.bd_input {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  // color: #606266;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 6px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 90px;
}
.percentage::before {
  content: '%';
  position: absolute;
  top: 0px;
  right: 8px;
}

// 改变input框背景颜色
/deep/.el-input__inner {
  background-color: transparent !important;
  border: 0 !important;
}
/* 利用穿透，设置input边框隐藏 */
.bd_input >>> .el-input__inner {
  border: 0;
}
.color {
  background-color: #f5e9057d;
}
</style>
